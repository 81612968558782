import { connect } from "react-redux";
import { Row, Col, Image, List } from "antd";
import styled from "styled-components";
import hmerida from "../assets/arcadia_hmerida.jpg";
import IntlMessages from "../utility/intlMessages";


// Get language from reducer 
const Events = ({ languageReducerProp: {language}}) => {
  const data = [
    {
      title: '10:00 - 10:15',
      event_el: 'Καλωσόρισμα',
      event_en: 'Welcome & General',
      presenter_el: '-',
      presenter_en: '-'
    },
    {
      title: '10:15 - 10:45',
      event_el: 'Επισκόπηση έργου: Βιώσιμα Κέντρα Δεδομένων (ΚΔ) μέσω αποτελεσματικής κλιμάκωσης υποδομής - 30λ',
      event_en: 'Project overview: Sustainable DCs through efficient infrastructure scaling - 30m',
      presenter_el: 'Γιώργος Παπαστεφανάτος - Κύριος Ερευνητής στο ΕΚ ΑΘΗΝΑ (Επιστημονικός Υπεύθυνος ARCADIA)',
      presenter_en: 'George Papastefanatos - Senior Researcher at ATHENA RC (ARCADIA Scientific Coordinator)',
    },
    {
      title: '10:45 - 11:05',
      event_el: 'Μέθοδοι πρόβλεψης για διακύμανση φόρτου εργασίας ΚΔ - 20λ',
      event_en: 'Predictive methods for DC workload variation - 20m',
      presenter_el: 'Δήμητρα Παρανού - Επιστημονικός Συνεργάτης στο ΕΚ ΑΘΗΝΑ (Υπεύθυνη Ενότητας Εργασίας 2)',
      presenter_en: 'Dimitra Paranou - Research Associate at ATHENA RC (Work Package 2 Leader)',
    },
    {
      title: '11:05 - 11:25',
      event_el: 'Αλγόριθμοι AI και ML για ΚΔ σωστού μεγέθους - 20λ',
      event_en: 'AI and ML algorithms for right-sizing DCs - 20m',
      presenter_el: 'Άγγελος Πεντέλας - Επιστήμονας Δεδομένων στην Intracom Telecom (Υπεύθυνος Ενότητας Εργασίας 3)',
      presenter_en: 'Angelos Pentelas - Data Scientist at Intracom Telecom (Work Package 3 Leader)',
    },
    {
      title: '11:25 - 11:45',
      event_el: 'Τα δεδομένα έχουν σημασία: προσομοίωση και συγκριτική αξιολόγηση της απόδοσης ΚΔ - 20λ',
      event_en: 'Data matters: simulating and benchmarking DC efficiency - 20m',
      presenter_el: 'Δήμητρα Παρανού - Επιστημονικός Συνεργάτης στο ΕΚ ΑΘΗΝΑ (Υπεύθυνη Ενότητας Εργασίας 2)',
      presenter_en: 'Dimitra Paranou - Research Associate at ATHENA RC (Work Package 2 Leader)',
    },
    {
      title: '11:45 - 12:00',
      event_el: 'Διάλειμμα για καφέ',
      event_en: 'Coffee Break',
      presenter_el: '-',
      presenter_en: '-'
    },
    {
      title: '12:00 - 12:20',
      event_el: 'Εξισορρόπηση ασφάλειας και αποδοτικότητας: Διερεύνηση χαρακτηριστικών ανθεκτικότητας – 20λ',
      event_en: 'Balancing Safety and Efficiency: Exploring Resilience Features – 20m',
      presenter_el: 'Ευάγγελος Αγγέλου - Κύριος Μηχανικός Μηχανικής Μάθησης στην Intracom Telecom (Υπεύθυνος Ενότητας Εργασίας 1)',
      presenter_en: 'Evangelos Angelou - Senior Machine Learning Engineer at Intracom Telecom (Work Package 1 Leader)',
    },
    {
      title: '12:20 - 12:40',
      event_el: 'Βελτίωση της αποδοτικότητας της τηλεπικοινωνιακής υποδομής – 20λ',
      event_en: 'Product Use Cases: Improving Telco infrastructure efficiency – 20m',
      presenter_el: 'Νίκος Αναστόπουλος - Κύριος Αρχιτέκτονας Λύσεων στην Intracom Telecom (Υπεύθυνος Έργου Καινοτομίας ARCADIA)',
      presenter_en: 'Nikos Anastopoulos - Principal Solution Architect at Intracom Telecom (Project Innovation Manager ARCADIA)',
    },
    {
      title: '12:40 - 14:00',
      event_el: 'Μεσημεριανό διάλειμμα',
      event_en: 'Lunch Break',
      presenter_el: '-',
      presenter_en: '-'
    },
    {
      title: '14:00 - 14:45',
      event_el: 'Συζήτηση στρογγυλής τραπέζης: Οικοδόμηση ενός βιώσιμου οικοσυστήματος πληροφορικής - Διδάγματα και στρατηγικές από ηγέτες της βιομηχανίας - 45λ',
      event_en: 'Roundtable: Building a Sustainable IT Ecosystem - Lessons and Strategies from Industry Leaders – 45m',
      presenter_el: 'Δήμητρα Τσόλκα - Διαχειρίστρια Έργων στην Intracom Telecom (Συντονίστρια Έργου ARCADIA)',
      presenter_en: 'Dimitra Tsolka - Project Manager at Intracom Telecom (Project Coordinator ARCADIA)',
      inst: 'Intracom Telecom',
    },
  ];

  return (
    <EventsPageWrapper>
      <Row>
        <Col span={24} className="text-align-center">
          <h1 className="page-title">
            <IntlMessages id={"events.title"} />
          </h1>
        </Col>
      </Row>

      <Row className="m-top-3" justify="center">
        <h2>
          <IntlMessages id={"events.hmerida"} />
        </h2>
      </Row>

      <Row className="m-top-3" justify="center">
       <Image src={hmerida} className="scaled-image" preview={false} width={1000}/>
      </Row>

      <Row className="m-top-3 d-grid text-align-center" justify="center">
        <p style={{ fontWeight: 600, fontSize: "30px", color:"#05a551"}}>
          <IntlMessages id={"events.hmerida_title1"} />
        </p>
        <p style={{ fontSize: "26px", color:"#05a551"}}>
          <IntlMessages id={"events.hmerida_title2"} />
          </p>
      </Row>

      <Row className="m-top-3" style={{margin: "0 12%"}}>
          {language.locale === "el" ? 
          (<span>Η Intracom Telecom, σε συνεργασία με το Ινστιτούτο Πληροφοριακών Συστημάτων του Ερευνητικού Κέντρου «Αθηνά», διοργανώνει την ημερίδα με θέμα την καινοτομία και τη χρήση τεχνητής νοημοσύνης στον τομέα της διαχείρισης ενέργειας μεγάλων data centers. Η εκδήλωση θα πραγματοποιηθεί την <b>Παρασκευή, 22 Νοεμβρίου 2024</b> από τις 09:30 έως τις 15:00, στο ξενοδοχείο <a href="https://maps.app.goo.gl/6h67keZcnmDpy59Z7" target="blank">The Golden Age Hotel of Athens</a> στην Αθήνα.</span>):
          (<span>Intracom Telecom, in collaboration with the Information Management Systems Institute of the "ATHENA" Research Center, organizes the conference on innovation and the use of artificial intelligence in the field of energy management of large data centers. The event will take place on <b>Friday, November 22, 2024</b>, from 09:30 to 15:00, at <a href="https://maps.app.goo.gl/6h67keZcnmDpy59Z7" target="blank">The Golden Age Hotel of Athens</a> in Athens.</span>)}
          <br />
          <br />
          <IntlMessages id={"events.info2"} />
          <br />
          <br />
          <IntlMessages id={"events.info3"} />
          <br />
      </Row>

      <Row className="m-top-3 d-grid" style={{margin: "0 12%"}}>
        <h3 style={{ fontWeight: 600}}>
          <IntlMessages id={"events.agenda"} />
        </h3>
        <p><IntlMessages id={"events.date"} /></p>
        <List
          size="large"
          bordered
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                title={item.title}
                description={(
                  <>
                    <p className="event">{item[`event_${language.locale}`]}</p>
                    {item[`presenter_${language.locale}`] !== '-' && <p>{item[`presenter_${language.locale}`]}</p>}
                  </>
                )} 
              />
            </List.Item>
          )}
        />
      </Row>
    </EventsPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  languageReducerProp: state.languageReducer,
});

export default connect(mapStateToProps, { })(Events);

const EventsPageWrapper = styled.div`
  margin: 30px;

  .ant-list-item-meta-title {
  font-weight: 600;
  font-size: 22px;
  
  }

  .ant-list-item-meta-description {
  font-size: 18px;
  }

  .event {
  color: rgba(0,0,0,.75);
  }

  .ant-list-split .ant-list-item {
    border-bottom: 4px solid var(--grey-color);
    background-color: white;
}

`;
